import React,{useEffect} from 'react'
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import { Router, Link } from "@reach/router"
import PropertyDetailPage from '@src/components/templates/propertyAndProjectPage/PropertyDetailPage'
import ImageGallery from '@src/components/templates/propertyAndProjectPage/ImageGallery'
import withRoot from '@src/withRoot'

const App = (props) => {
    const {
        propertyStore,
        generalPropertyStore,
        uiStore,
        appStore,
        pageContext: { data:passedData }
    } = props
    const data = propertyStore.additionalProcess(generalPropertyStore.processData(passedData))
    // console.log(data)
    propertyStore.setCurrentDetails(data)
    useEffect(() => {
        uiStore.setExplorePageCurrentTab(null)
    }, [])

    useEffect(() => {
        propertyStore.initPropertyDetailData_(passedData)
    }, [appStore.loggedInUser])
    return <div style={{position:'relative'}}>
        {/* {data.processedData.isProject ?
        <Router basepath='/project/:id'>
            <PropertyDetailPage path="/"/>
            <ImageGallery path="/gallery"/>
            <ImageGallery path="/planGallery/:planId"/>
        </Router>
        :
        <Router basepath='/property/:id'>
            <PropertyDetailPage path="/"/>
            <ImageGallery path="/gallery"/>
            <ImageGallery path="/planGallery"/>
        </Router>
        } */}
        <PropertyDetailPage/>
    </div>
}

export default compose(
    withRoot,
    inject('propertyStore','generalPropertyStore','uiStore','appStore'),
    observer
)(App)

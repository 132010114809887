import React,{useEffect} from 'react'
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import { Layout, Menu } from "antd"
import ExploreHeader from '@src/components/layout/ExploreHeader'
import GalleryHeader from '@src/components/layout/GalleryHeader'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import MePanel from '@src/components/setting/MePanel'
import PropertyDetailPageContent from '@src/components/property/PropertyDetailPageContent'
import PropertyGallery from '@src/components/property/PropertyGallery'
import Notifier from '@src/components/Notifier'
import LandingPageOptions from '@src/components/LandingPageOptions'
import { Router, Link } from "@reach/router"
import find from 'lodash/find'
import DeveloperDetailSidePanel from '@src/components/property/DeveloperDetailSidePanel'
import withRoot from '@src/withRoot'

const { Header, Footer, Content } = Layout

const PropertyDetailPage = compose(
    inject('propertyStore','generalPropertyStore','uiStore'),
    observer
)(props=>{
    const {
        propertyStore,
    } = props
    const {currentDetails:data} = propertyStore
    return (
        <Layout style={{backgroundColor:'white',maxWidth:1440,margin: "0 auto"}}>
            <Notifier />
            <ExploreHeader/>
            <Content style={{position:"relative"}}>
                <MePanel/>
                <DeveloperDetailSidePanel/>
                <PropertyDetailPageContent data={data}/>
                <div style={{
                    margin:32,
                    marginLeft:64,
                    marginRight:64
                }}>
                    <LandingPageOptions/>
                </div>
            </Content>
        </Layout>
    )
})

export default withRoot(PropertyDetailPage)
import React,{useState} from 'react'
import { motion } from "framer-motion"; 
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
import {inject,observer} from 'mobx-react'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import compose from "recompose/compose";
import {ReactComponent as Phone} from '@src/assets/svgIcons/Phone.svg'
import {ReactComponent as Cross} from '@src/assets/svgIcons/Cross.svg'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography';
import find from 'lodash/find'
import _ from 'lodash'
import Divider from '@material-ui/core/Divider'
import { useAsyncCall } from 'gx-web-ui/utils/hook'
import { CircularProgress } from '@material-ui/core';
import PropertyCard from '@src/components/property/PropertyCard'
import ProjectCard from '@src/components/property/ProjectCard'
import {clone} from '@src/utils/typeCheck'
import useOnclickOutside from "react-cool-onclickoutside";
import ReactPlayer from 'react-player'
import {propertySlugGeneratorFromRaw} from '@src/utils/slug'  
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropertyCarousel from '@src/components/property/PropertyCarousel'

const sidebar = {
    open: {
        x:0,
        display:'block',
    },
    closed: {
        x:700,
        transitionEnd:{
            display:'none'
        }
    }
};

const DeveloperDetailSidePanel = (props) => {
    const {uiStore,propertyStore,appStore,generalPropertyStore} = props
    const {checkLogin} = uiStore
    const {
        developerDetailPanelOpen,
        setDeveloperDetailPanelOpen
    } = uiStore
    const {currentDetails,unfavorProperty_,favorProperty_,toggleFavorOfCurrentDetails,additionalProcess} = propertyStore

    const [currentIndex, setCurrentIndex] = useState(0)
    const [data, setData] = useState({})
    const { loading, result, error } = useAsyncCall(
        async () => {
            if(developerDetailPanelOpen){
                const res = await Promise.all([
                    appStore.clientApi.getDeveloperDetail_(currentDetails.rawData.developer),
                    appStore.clientApi.getPropertyByDeveloperId_(currentDetails.rawData.developer)
                ])
                console.log(res)
                const developerData = res[0]
                let banners = developerData[':resourceGroup'] && developerData[':resourceGroup'][':resources'] ? generalPropertyStore.processBanners(developerData[":resourceGroup"]?.[':resources']):[]
                banners = banners.length===0?generalPropertyStore.defaultBanner:banners
                const videoIndex = _.findIndex(banners,'video')
                if(videoIndex>=0){
                    let reorderBanners = banners.filter(banner=>!banner.video)
                    reorderBanners.splice(1,0,banners[videoIndex])
                    banners = reorderBanners
                }

                const logo = developerData.agency ? developerData.logo : developerData.avatar;
                const data = {
                    agency: developerData.agency,
                    developerName:developerData.name,
                    logo:logo||require('@src/assets/images/default-logo.png'),
                    avatar: developerData.avatar,
                    phoneNumber:developerData.phone,
                    address:developerData?.[":address"]?.fullText,
                    desc:developerData.about,
                    website:developerData.website,
                    projects:generalPropertyStore.processListData(res[1].items).map(item=>additionalProcess(item)),
                    video:find(developerData[":resourceGroup"]?.[':resources']||[],{type:'VIDEO'})?.url,
                    // banners:(developerData[":resourceGroup"]?.[':resources']||[]).filter(item=>item.type==='IMAGE'),
                    banners:banners
                }
                console.log(data)
                return data
            }else{
                return {}
            }
        },
        [developerDetailPanelOpen],
        (data)=>{
            setData(data)
            return data
        }
    );

    const styles = useStyles()
    const theme = useTheme()
    const boxShadow = {boxShadow:`0px 4px 10px 0px ${theme.palette.grey[400]}`}
    const containerRef = useOnclickOutside(() => {
        setDeveloperDetailPanelOpen(false)
    },{
        ignoreClass:'ignore-class',
    });


    const onToggleFavor = (listingId,index)=>(newState)=>{
        if(newState===true){
            favorProperty_(listingId)
        }else{
            unfavorProperty_(listingId)
        }
        const cp = clone(data)
        cp.projects[index].processedData.isSaved = newState
        setData(cp)
        if(listingId === currentDetails.processedData.listingId){
            toggleFavorOfCurrentDetails(newState)
        }

    }

    const onClickWebsite = ()=>{
        if(data?.website){
            window.open(data?.website,'_blank')
        }
    }

    React.useEffect(() => {
        if(developerDetailPanelOpen==false){
            setCurrentIndex(0)
        }
    }, [developerDetailPanelOpen])
    

    return (
        <div style={{position:"sticky",top:0,zIndex:4}}>
            <motion.div 
                initial={false}
                animate={developerDetailPanelOpen ? "open" : "closed"}
                className={styles.root}
                variants={sidebar}
                transition={{duration:0.2}}
                ref={containerRef}
            >
                <div className={styles.header}>
                    <IconButton className={styles.cross} onClick={()=>setDeveloperDetailPanelOpen(false)}>
                        <Cross height={16} width={16} fill={theme.palette.grey[700]}/>
                    </IconButton>
                    <Typography align='center' className={styles.title}>Developer details</Typography>
                </div>
                {loading || developerDetailPanelOpen===false?
                <CircularProgress size={20}/>
                :
                <React.Fragment>
                    {/* {data.video &&
                    <ReactPlayer 
                        light={data.banners?data.banners[0].url:false}
                        width={'100%'} 
                        height={'225px'} 
                        url={data.video} 
                        playing
                        controls
                        style={{marginTop:8,marginBottom:8,backgroundColor:'black'}}
                    />
                    } */}
                    <div style={{marginTop:8,marginBottom:8}}>
                        {
                            data.agency && data.avatar ? 
                            <img src={data.avatar} className={styles.img} />
                            :
                            <PropertyCarousel 
                                currentIndex={currentIndex} 
                                setCurrentIndex={setCurrentIndex} 
                                height={225} 
                                banners={data.banners}
                            />
                        }
                    </div> 
                    <div className={styles.developerInfo}>
                        <img src={data.logo} className={styles.logo}/>
                        <div>
                            <Typography className={styles.name}>{data.developerName}</Typography>
                            <div className={styles.alignTop}>
                                <Phone width={12} height={12} fill={'rgba(0,0,0,0.5)'} style={{marginRight:4,marginTop:4}}/>
                                <Typography className={styles.infoText}>{data.phoneNumber}</Typography>
                            </div>
                            <div className={styles.alignTop} >
                                <img src={require('@src/images/transparentLogo.png')} className={styles.logoImg} style={{marginRight:4}}/>
                                <Typography className={styles.infoText}>{data.address}</Typography>
                            </div>
                        </div>
                    </div>
                    <Divider className={styles.divider}/>
                    <Typography className={styles.sectionTitle}>• About {data.developerName}</Typography>
                    {data.desc}
                    <Divider className={styles.divider}/>
                    <div>
                        <Typography className={styles.sectionTitle}>
                            • Website
                        </Typography>
                        <Typography className={styles.link} onClick={onClickWebsite}>
                            {data.website}
                        </Typography>
                    </div>
                    <Divider className={styles.divider}/>
                    <Typography className={styles.sectionTitle}>• Projects</Typography>
                    <div className={styles.projects}>
                        {data.projects && data.projects.map((data,index)=>
                        <div className={styles.card} key={data.rawData.id}>
                            {data.processedData.isProject?
                            <ProjectCard 
                                project={data.processedData} 
                                listMode 
                                rootStyle={boxShadow}
                                favored={data.processedData.isSaved}
                                onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')}
                                onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index))}
                            />
                            :
                            <PropertyCard 
                                onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')}
                                property={data.processedData} 
                                rootStyle={boxShadow} 
                                favored={data.processedData.isSaved}
                                onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index))}
                            />
                            }
                        </div>
                        )}
                    </div>
                </React.Fragment>
                }
            </motion.div>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    root:{
        position:'absolute',
        top:0,
        bottom:0,
        right:0,
        height:'100vh',
        width:400,
        background: 'white',
        WebkitBoxShadow:'-9px 1px 19px -2px rgba(0,0,0,0.2)',
        MozBoxShadow:'-9px 1px 19px -2px rgba(0,0,0,0.2)',
        boxShadow:'-9px 1px 19px -2px rgba(0,0,0,0.2)',
        zIndex:4,
        padding:24,
        overflow:'scroll',
    },
    header:{
        position:'relative',
        width:'100%'
    },
    title:{
        fontSize:20,
        color:'rgba(0, 0, 0, 0.5)',
        fontWeight:500
    },
    cross:{
        position:'absolute',
        right:-6,
        top:-6
    },
    logo:{
        height:50,
        width:50,
        marginRight:8
    },
    developerInfo:{
        ...hBox('center'),
        // marginTop:8
    },
    alignTop:{
        ...hBox('top')
    },
    logoImg:{
        height:'auto',
        width:12,
        opacity:0.5,
        paddingTop:2
    },
    infoText:{
        fontWeight:500,
        fontSize:14,
        color:'rgba(0,0,0,0.3)'
    },
    name:{
        fontWeight:500,
        fontSize:18
    },
    divider:{
        marginTop:12,
        marginBottom:12
    },
    sectionTitle:{
        fontSize:18,
        color:'rgba(0,0,0,0.9)',
        fontWeight:500
    },
    card:{
        marginBottom: 16,
    },
    projects:{
        marginTop:16,
    },
    alignH:{
        ...hBox('center')
    },
    link:{
        cursor:'pointer',
        textDecoration:'underline',
    },
    img: {
        objectFit:'cover',
        height: 225,
        width: '100%',
    }
}))
export default compose(
    inject('uiStore','propertyStore','appStore','generalPropertyStore'),
    observer
)(DeveloperDetailSidePanel)
